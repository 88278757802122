// Your variable overrides
$sidebar-bg-color: #363740;
$app-bg-color: #f7f8fc;
@import '~react-pro-sidebar/dist/scss/styles.scss';


.sidebar-column {
    max-width: $sidebar-width;
}

.app-styling { 
    background-color: $app-bg-color;
    width: 100vw; 
    margin: 0px !important;
    padding: 0px;

}

.dashboard { 
    padding: 5em;
}
  