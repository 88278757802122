.login-region, .signup-region {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-region .card-body, .signup-region .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-card {
    margin-left: 2rem;
    margin-right: 2rem;
    width: 100%;
    max-width: 350px;
}

.signup-region .login-card {
    max-width: 400px;
}

.signup-region .btn, .login-region .btn {
    width: 100%;
    height: 56px;
}

.login-region .btn.btn-link {
    height: 40px;
    width: 100%;
}

.login-image {
    width: 100px;
    height: 100px;
}

.login-bk-top {
    height: 50vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary);
    width: 100%;
}

.login-bk-bot {
    height: 50vh;
    position: absolute;
    top: 50vh;
    left: 0;
    background-color: #EFEFEF;
    width: 100%;
}

.error-card {
    animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translate(10px, 0px); }
  10% { transform: translate(-9px, 0px); }
  20% { transform: translate(8px, 0px); }
  30% { transform: translate(-7px, 0px); }
  40% { transform: translate(6px, 0px); }
  50% { transform: translate(-5px, 0px); }
  60% { transform: translate(4px, 0px); }
  70% { transform: translate(-3px, 0px); }
  80% { transform: translate(2px, 0px); }
  90% { transform: translate(-1px, 0px); }
  100% { transform: translate(0px, 0px); }
}
