.metric-card-collection {
    display: flex;
    margin-top: 20px;
    height: 150px;
}

.metric-card-collection .metrics-card:first-child {
    margin-left: 0;
}
.metric-card-collection .metrics-card:last-child {
    margin-right: 0;
}

.metrics-card {
    margin-left: 10px;
    margin-right: 10px;
    flex: 1;
}

.graph-card {
    flex: 2;
    margin-right: -10px;
}

.flex-1 {
    flex: 1;
    margin-left: 30px;
}