.full-height {
  height: 100vh !important;
}

.no-underline {
  text-decoration: none;
}

.dashboard-content.container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.card.settings-card .card-body b {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0.5rem;
  display: flex;
}

.setting-group {
  margin-bottom: 2rem;
}

.browser-preview {
  flex: 2;
  height: 400px;
  position: sticky;
  top: 50px;
}
.card.settings-card {
  flex: 1;
  margin-right: 2rem;
}

.web-embed-card {
  height: 450px;
  margin-bottom: 50px;
  flex: 2;
}

.bar {
    height: 40px;
    width: 100%;
    background-color: #EFEFEF;
    margin-bottom: 20px;
    border-radius: 5px;
}

.bar-spacer {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
}

.web-embed-body {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
}

.shiftsy-sidebar {
  min-height: 100vh;
}

img.sidebar-logo {
  height: 50px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: none;
}

.sidebar-text {
  font-weight: 600;
  font-size: 20px;
  margin-left: 0.25rem;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  background-color: #111111;
}