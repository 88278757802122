.card.pricing-card {
    height: 400px;
    margin-right: 20px;
    flex: 1;
}

.card b.plan-cost {
    font-size: 2em;
    margin-bottom: 0;
}

.card .plan-cost-region {
    display: flex;
    align-items: flex-end;
    font-size: 2em;
}

.card.pricing-card:last-child {
    margin-right: 0;
}

.billing-container {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}